import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import UserContextProvider from './context/auth/UserProvider';
import NotificationsContexProvider from './context/notifications/NotificationsProvider';
import CacheBuster from './views/CacheBuster';
import ErrorBoundary from './components/common/ErrorBoundary';

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://c8e514035047a08f9b3802d19c40ea61@o488078.ingest.us.sentry.io/4507019968053248',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.8, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://v2-dev.moichor.us'],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root'));

root.render(
  <CacheBuster>
    <ErrorBoundary>
      <Router>
        <UserContextProvider>
          <NotificationsContexProvider>
            <Suspense fallback={<div className="loading" />}>
              <App />
            </Suspense>
          </NotificationsContexProvider>
        </UserContextProvider>
      </Router>
    </ErrorBoundary>
  </CacheBuster>,
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
