import React, {
  createContext,
  useContext,
  useState,
} from 'react';

const NotificationsContext = createContext({});

export const useNotificationsContext = () => useContext(NotificationsContext);

const NotificationsContexProvider = ({ children }) => {
  const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0);

  return (
    <NotificationsContext.Provider value={{
      unreadMessagesNumber,
      setUnreadMessagesNumber,
    }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContexProvider;
