/* eslint-disable func-names */
import React, {
  createContext, useReducer, useContext, useMemo, useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../constants/axiosService';
import reducer, { INIT_STATE } from './reducer';
import {
  loginUserSuccess,
  loginUserError,
  loginUserSuccess2fa,
  loginUserError2fa,
  loginUser,
  loginUser2fa,
  logoutUser,
  getCurrentUser as getCurrentUserAction,
} from './actions';
import localStorageKeys from '../../constants/localStorageKeys';

const BASE_API = process.env.REACT_APP_BASE_API;

const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const history = useHistory();
  const location = useLocation();

  const allRoles = useMemo(() => {
    const roles = state?.user?.roles || [];
    return roles.map((role) => role.slug);
  }, [state?.user?.roles]);

  const allPermissions = useMemo(() => {
    const permissions = state?.user?.permissions || [];
    (state?.user?.roles ?? []).forEach((role) => {
      permissions.push(...role.permissions);
    });
    return permissions.map((permission) => permission.slug);
  }, [state?.user?.roles, state?.user?.permissions]);
  // eslint-disable-next-line max-len
  const usePermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);
  const getPermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);
  const setLocalData = ({ accessToken, refreshToken }, user) => {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    localStorage.setItem(localStorageKeys.moichorToken, accessToken);
    localStorage.setItem(localStorageKeys.moichorRefreshToken, refreshToken);
    localStorage.setItem(localStorageKeys.moichorUser, JSON.stringify(user));
  };

  const clearLocalData = () => {
    axios.defaults.headers.common.Authorization = null;
    localStorage.removeItem(localStorageKeys.moichorToken);
    localStorage.removeItem(localStorageKeys.moichorRefreshToken);
  };

  const loginWithEmailPassword = async (user) => {
    const {
      email, password, code, recaptchaResponse,
    } = user;
    dispatch(loginUser(user));
    try {
      const { data } = await axios.post(`${BASE_API}/api/v1/auth/login`, {
        email, password, code, recaptchaResponse,
      });
      if (data) {
        const { user, accessToken, refreshToken } = data;
        if (user && accessToken && refreshToken) {
          setLocalData({ accessToken, refreshToken }, user);
          dispatch(loginUserSuccess(user));
          history.push((location.state && location.state.from.pathname) || '/');
        }
      }
      if (!data) dispatch(loginUserError('Error.'));
    } catch (error) {
      dispatch(loginUserError(error.data?.message || error?.message || 'Error.'));
    }
  };

  const loginWithCode = async (user) => {
    const { code, id, phoneNumber = null } = user;
    dispatch(loginUser2fa(user));
    try {
      const { data } = await axios.post(`${BASE_API}/signin-2fa`, { code, id, phoneNumber });

      if (data) {
        const { user, verified, authData } = data;
        if (verified) {
          if (authData) {
            setLocalData(authData, user);
            dispatch(loginUserSuccess2fa(user));
            localStorage.removeItem(localStorageKeys.moichorQRCode);
            history.push('/');
          }
        } else if (!verified) {
          dispatch(loginUserError2fa('The code was not verified. Please, try again.'));
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('login error : ', error);
    }
  };

  const logout = () => {
    dispatch(logoutUser());
    try {
      dispatch(loginUserSuccess(null));
      clearLocalData();
      history.push('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('logout failed :', error);
    }
  };

  const getCurrentUser = async () => {
    dispatch(getCurrentUserAction());
    try {
      const { data } = await axios.get(`${BASE_API}/api/v1/auth/profile`);
      if (data) {
        dispatch(loginUserSuccess(data));
      } else {
        dispatch(loginUserSuccess(null));
        clearLocalData();
        history.push('/');
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('login failed :', error);
      return Promise.reject(error);
    }
  };

  return (
    <UserContext.Provider value={{
      ...state,
      loginWithEmailPassword,
      loginWithCode,
      logout,
      getCurrentUser,
      usePermission,
      getPermission,
      setLocalData,
      allRoles,
      allPermissions,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const withUserContext = (Component) => (
  function (props) {
    return (
      <UserContext.Consumer>
        {(state) => <Component {...props} {...state} />}
      </UserContext.Consumer>
    );
  }
);

export default UserContextProvider;
