import _ from 'lodash';
import moment from 'moment';
import { getIsCAdminRole, getIsUserRole } from '../helpers/getUserRole';

export const defaultMenuType = 'menu-default';
export const defaultDateFormat = 'MM/DD/YYYY';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [{ id: 'en', name: 'English - LTR', direction: 'ltr' }];
export const pageSize = Math.max(Math.ceil(window.innerHeight / 20), 48);

export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const moichorTermsUrl = 'https://moichor.com/terms-conditions';
export const BASE_API = process.env.REACT_APP_BASE_API;
export const SERVER_URL = `${BASE_API}/v1/`;

export const pathImageSize = 460;

export const invoicePaymentStatus = {
  paid: 1,
  unpaid: 2,
  failed: 3,
  all: '',
};

export const shipmentTypes = {
  SUPPLY_REQUEST: 1,
  TEST_SAMPLE: 2,
  SAMPLE_FORWARDING: 3,
};

export const shipmentStatuses = {
  ordered: 'ORDERED',
  pending: 'PENDING',
  canceled: 'CANCELED',
  delivered: 'DELIVERED',
  all: '',
};

export const invoiceIssueStatus = {
  issued: true,
  created: false,
  all: '',
};

export const getGenderLabel = (gender) => {
  switch (gender) {
    case null: return 'Default';
    case 2: return 'Male';
    case 4: return 'Female';
    case 8: return 'Neutered Male';
    case 16: return 'Neutered Female';
    default: return 'Unknown';
  }
};

export const testStatus = {
  completed: 2,
  created: 4,
  failure: 8,
  pending: 16,
  in_validation: 32,
  in_review: 64,
  in_shipment: 128,
  pathology_review: 256,
  review_completed: 512,
  sample_received: 1024,
  processing: 2048,
  preliminary_report: 4096,
  reprocess: 8192,
};

export const orderStatuses = {
  created: 'created',
  billed: 'billed',
  paid: 'paid',
  refunding: 'refunding',
  refundingAndBilled: 'refund',
  refunded: 'refunded',
  cancelled: 'cancelled',
};

export const testPriority = {
  low: 2,
  normal: 4,
  high: 8,
};

export const modalModes = {
  create: 1,
  update: 2,
  delete: 3,
};

export const getTestStatusLabel = (status) => {
  switch (status) {
    case testStatus.created:
      return { color: 'light', label: 'Created' };
    case testStatus.pending:
      return { color: 'info', label: 'Pending' };
    case testStatus.failure:
      return { color: 'danger', label: 'Failure' };
    case testStatus.completed:
      return { color: 'success', label: 'Completed' };
    case testStatus.in_validation:
      return { color: 'dark-blue', label: 'In Validation' };
    case testStatus.in_review:
      return { color: 'warning', label: 'In Review ' };
    case testStatus.in_shipment:
      return { color: 'light', label: 'In Shipment ' };
    case testStatus.pathology_review:
      return { color: 'purple', label: 'Pathology Review' };
    case testStatus.review_completed:
      return { color: 'dark-blue', label: 'Review Completed' };
    case testStatus.sample_received:
      return { color: 'dark', label: 'Sample Received' };
    case testStatus.processing:
      return { color: 'info', label: 'Processing' };
    case testStatus.preliminary_report:
      return { color: 'success', label: 'Preliminary Report' };
    case testStatus.reprocess:
      return { color: 'info', label: 'Reprocess' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const getShipmentStatusLabel = (key) => {
  switch (key) {
    case 'ORDERED':
      return { title: 'CREATED', color: 'light' };
    case 'DELIVERED':
      return { title: 'DELIVERED', color: 'success' };
    case 'CANCELED':
      return { title: 'CANCELED', color: 'danger' };
    default:
      return { title: 'PENDING', color: 'info' };
  }
};

export const getPVAvailabilityStatusLabel = (status) => {
  if (status) return { color: 'light', label: 'Unavailable' };
  return { color: 'success', label: 'Available' };
};

export const suppliesListOrderOptions = [
  { column: 'dateCreated', label: 'Date created: newer' },
  { column: 'dateCreatedOld', label: 'Date created: older' },
  // { column: 'supplyStatus', label: 'Shipment Status' },
];
export const scheduleFilterOptions = [
  { column: 'lab', label: 'By LAB' },
];

export const billingStatuses = {
  created: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: false,
    is_cancelled: false,
  },
  paid: {
    is_paid: true,
    is_refunding: false,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
  cancelled: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: false,
    is_cancelled: true,
  },
  refunding: {
    is_paid: true,
    is_refunding: true,
    is_refunded: false,
    is_billed: false,
    is_cancelled: false,
  },
  refundingAndBilled: {
    is_paid: true,
    is_refunding: true,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
  refunded: {
    is_paid: true,
    is_refunding: false,
    is_refunded: true,
    is_billed: true,
    is_cancelled: false,
  },
  billed: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
};

export const getStatusName = (order = {}) => {
  const status = {
    is_paid: order.is_paid,
    is_refunding: order.is_refunding,
    is_refunded: order.is_refunded,
    is_billed: order.is_billed,
    is_cancelled: order.is_cancelled,
  };

  if (_.isEqual(status, billingStatuses.cancelled)) return 'cancelled';
  if (_.isEqual(status, billingStatuses.refundingAndBilled)) return 'refund';
  if (_.isEqual(status, billingStatuses.refunding)) return 'refunding';
  if (_.isEqual(status, billingStatuses.refunded)) return 'refunded';
  if (_.isEqual(status, billingStatuses.paid)) return 'paid';
  if (_.isEqual(status, billingStatuses.created)) return 'created';
  if (_.isEqual(status, billingStatuses.billed)) return 'billed';
  return 'other';
};

export const getStatusState = (value) => {
  switch (value) {
    case 'cancelled':
      return billingStatuses.cancelled;
    case 'refunding':
      return billingStatuses.refunding;
    case 'refund':
      return billingStatuses.refundingAndBilled;
    case 'refunded':
      return billingStatuses.refunded;
    case 'paid':
      return billingStatuses.paid;
    case 'billed':
      return billingStatuses.billed;
    case 'created':
      return billingStatuses.created;
    default:
      return {};
  }
};

export const getBillingStatusLabel = (order = {}) => {
  const statusName = getStatusName(order);
  switch (statusName) {
    case 'cancelled':
      return { color: 'dark', label: 'cancelled' };
    case 'refunding':
      return { color: 'warning', label: 'refunding' };
    case 'refund':
      return { color: 'info', label: 'refund' };
    case 'refunded':
      return { color: 'dark', label: 'refunded' };
    case 'paid':
      return { color: 'success', label: 'paid' };
    case 'created':
      return { color: 'light', label: 'created' };
    case 'billed':
      return { color: 'info', label: 'billed' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const getTestPriorityLabel = (priority) => {
  switch (priority) {
    case testPriority.low:
      return { color: 'success', label: 'low' };
    case testPriority.normal:
      return { color: 'info', label: 'normal' };
    case testPriority.high:
      return { color: 'danger', label: 'stat' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const getIssueStatus = (status) => {
  switch (status) {
    case invoiceIssueStatus.issued:
      return { color: 'success', label: 'issued' };
    case invoiceIssueStatus.created:
      return { color: 'warning', label: 'created' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const getPaymentStatus = (status) => {
  switch (+status) {
    case invoicePaymentStatus.paid:
      return { color: 'light', label: 'paid' };
    case invoicePaymentStatus.unpaid:
      return { color: 'dark', label: 'unpaid' };
    case invoicePaymentStatus.failed:
      return { color: 'danger', label: 'Failed' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const specialDisplayValueRow = (res) => {
  switch (res) {
    case -1:
      return 'Sample Quantity Insufficient';
    case -2:
      return 'Sample Quality';
    case -3:
      return 'High';
    case -4:
      return 'Low';
    default:
      return 'Not Available';
  }
};

export const validateEmail = (email) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
export const validateMultipleEmail = (email) => (email?.split(',')
  .every((email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())));

export const roles = {
  ROOTADMIN: 'rootadmin',
  SYSADMIN: 'sysadmin',
  C_ADMIN: 'c_admin',
  OP_ADMIN: 'op_admin',
  LAB_ADMIN: 'lab_admin',
  C_WORKER: 'c_worker',
  USER: 'user',
  A_USER: 'a_user',
};

export const getUserRoleLabel = (role) => {
  switch (role) {
    case roles.ROOTADMIN:
      return { color: 'dark', label: 'root' };
    case roles.SYSADMIN:
      return { color: 'success', label: 'system' };
    case roles.C_ADMIN:
      return { color: 'warning', label: 'clinical admin' };
    case roles.OP_ADMIN:
      return { color: 'info', label: 'customer service admin' };
    case roles.LAB_ADMIN:
      return { color: 'warning', label: 'laboratory admin' };
    case roles.C_WORKER:
      return { color: 'warning', label: 'clinical worker' };
    case roles.USER:
      return { color: 'light', label: 'user' };
    case roles.A_USER:
      return { color: 'light', label: 'admin user' };
    default:
      return { color: 'primary', label: `other (${role})` };
  }
};

export const getAlertStatusLabel = (alert) => {
  if (alert.is_deleted) return { color: 'danger', label: 'deleted' };
  if (!moment().isBefore(alert.date_expired)) return { color: 'light', label: 'expired' };
  if (alert.payment_status === '1') return { color: 'primary', label: 'paid' };
  return { color: 'success', label: 'active' };
};

export const clinicalTemplateTypeOptions = [
  { label: 'Template', value: 0 },
  { label: 'Symptom', value: 1 },
  { label: 'Diagnosis', value: 2 },
  { label: 'Terminology', value: 3 },
  { label: 'Procedure', value: 4 },
  { label: 'Treatment', value: 5 },
  { label: 'Diagnostic', value: 6 },
  { label: 'Reference', value: 7 },
];

export const getAlertLevelLabel = (level) => {
  switch (level) {
    case 'message':
      return { color: 'info', label: 'message' };
    case 'critical':
      return { color: 'danger', label: 'critical' };
    default:
      return { color: 'primary', label: 'other' };
  }
};

export const inDollars = (num) => `${(num / 100).toFixed(2)} $`;

export const accountTypeLabels = [
  {
    value: 'broker',
    label: 'Broker',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'limited_partner',
    label: 'Limited Partner',
  },
];

export const testListOrderOptions = [
  { column: 'dateCreated', label: 'Date created: newer' },
  { column: 'dateCreatedOld', label: 'Date created: older' },
  { column: 'priority', label: 'Priority: High to low' },
  { column: 'timezone', label: 'Timezone' },
];

export const refTestsListOrderOptions = [
  { column: 'latest', label: 'Latest' },
  { column: 'oldest', label: 'Oldest' },
  { column: 'name', label: 'Name' },
];

export const refVariablesListOrderOptions = [
  // { column: 'id', label: 'ID' },
  { column: 'latest', label: 'Latest' },
  { column: 'oldest', label: 'Oldest' },
  { column: 'name', label: 'Name' },
];

export const refValuesListOrderOptions = [
  { column: 'variable', label: 'Variable' },
  { column: 'species', label: 'Species' },
];

export const pathologyImageStatuses = {
  valid: 0,
  invalid: 1,
};

export const variableStatusOptions = [
  {
    value: 0,
    label: 'Show only when value has referenceValue',
  },
  {
    value: 1,
    label: 'Show always',
  },
  {
    value: 2,
    label: 'Do not display on client portal',
  },
];

export const variableImageStatusOptions = [
  {
    value: 1,
    label: 'Show',
  },
  {
    value: 2,
    label: 'Don\'t show',
  },
];

export const shippingServiceOptions = [
  {
    value: 'fedEx',
    label: 'FedEx',
  },
  {
    value: 'ups',
    label: 'UPS',
  },
];

export const upsPackageTypeOptions = [
  {
    value: 'UPS_Express_Box_Large',
    label: 'Express Box Large (18.00 x 13.00 x 3.00 in)',
  },
  {
    value: 'UPS_Express_Box_Medium',
    label: 'Express Box Medium (15.00 x 11.00 x 3.00 in)',
  },
  {
    value: 'UPS_Express_Box_Small',
    label: 'Express Box Small (13.00 x 11.00 x 2.00 in)',
  },
];

export const getMessageInfo = (roles, userId, user) => {
  if (user && user.firstname) return { title: `${user.firstname} ${user.lastname}` };
  if (getIsUserRole(roles)) {
    return {
      title: `Clinic User${userId ? ` #${userId}` : ''}`,
    };
  } if (getIsCAdminRole(roles)) {
    return {
      title: 'Moichor Clinical',
    };
  }
  return {
    title: 'Moichor System',
  };
};

export const sidebarStatusClasses = {
  sm: {
    SHOW: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile main-show-temporary sub-show-temporary',
    SUB_HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile',
    HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile main-show-temporary',
  },
  lg: {
    SHOW: 'menu-default',
    SUB_HIDDEN: 'menu-default sub-hidden',
    HIDDEN: 'menu-default sub-hidden main-hidden',
  },
  md: {
    SHOW: 'menu-default menu-sub-hidden sub-show-temporary',
    SUB_HIDDEN: 'menu-default menu-sub-hidden',
    HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden',
  },
};

export const resultActions = {
  PV: 'pv',
  MANUAL_REVIEW: 'manualreview',
  QUESTIONNARE: 'questionnare',
  RD_REVIEW: 'rdreview',
};

export const resultActionsLabels = [
  { value: resultActions.PV, label: 'Pathology Review' },
  { value: resultActions.MANUAL_REVIEW, label: 'Manual Review' },
  { value: resultActions.QUESTIONNARE, label: 'Questionnare' },
  { value: resultActions.RD_REVIEW, label: 'R & D Review' },
];

export const sortOptions = [
  { value: 'ASC', label: 'ASC' },
  { value: 'DESC', label: 'DESC' },
];

export const binPriorityOptions = [
  {
    value: 8,
    label: 'HIGH',
  },
  {
    value: 4,
    label: 'MEDIUM',
  },
  {
    value: 2,
    label: 'LOW',
  },
];

export const binTimeZoneOptions = [
  {
    value: 'HST - HDT',
    label: 'Hawaiian Standard Time(GMT - 10)',
  },
  {
    value: 'AST - ADT',
    label: 'Alaska Standard Time(GMT - 9)',
  },
  {
    value: 'PST - PDT',
    label: 'Pacific Standard Time(GMT - 8)',
  },
  {
    value: 'MST - MDT',
    label: 'Mountain Standard Time(GMT - 7)',
  },
  {

    value: 'CST - CDT',
    label: 'Central Standard Time(GMT - 6)',
  },
  {
    value: 'EST - EDT',
    label: 'Eastern Standard Time(GMT - 5)',
  },
];

export const issueStatusOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const PatientGender = {
  DEFAULT: null,
  MALE: 2,
  FEMALE: 4,
  UNKNOWN: 8,
  NEUTEREDMALE: 16,
  NEUTEREDFEMALE: 32,
};

export const ShipmentCheckoutStatus = {
  ORDERED: 0,
  RECEIVED: 1,
  CHECKOUT: 2,
};

export const currencyOptions = [
  { label: 'Afghan Afghani', value: 'AFN' },
  { label: 'Albanian Lek', value: 'ALL' },
  { label: 'Algerian Dinar', value: 'DZD' },
  { label: 'Angolan Kwanza', value: 'AOA' },
  { label: 'Argentine Peso', value: 'ARS' },
  { label: 'Armenian Dram', value: 'AMD' },
  { label: 'Aruban Florin', value: 'AWG' },
  { label: 'Australian Dollar', value: 'AUD' },
  { label: 'Azerbaijani Manat', value: 'AZN' },
  { label: 'Bahamian Dollar', value: 'BSD' },
  { label: 'Bangladeshi Taka', value: 'BDT' },
  { label: 'Barbadian Dollar', value: 'BBD' },
  { label: 'Belize Dollar', value: 'BZD' },
  { label: 'Bermudian Dollar', value: 'BMD' },
  { label: 'Bolivian Boliviano', value: 'BOB' },
  { label: 'Bosnia & Herzegovina Convertible Mark', value: 'BAM' },
  { label: 'Botswana Pula', value: 'BWP' },
  { label: 'Brazilian Real', value: 'BRL' },
  { label: 'British Pound', value: 'GBP' },
  { label: 'Brunei Dollar', value: 'BND' },
  { label: 'Bulgarian Lev', value: 'BGN' },
  { label: 'Burundian Franc', value: 'BIF' },
  { label: 'Cambodian Riel', value: 'KHR' },
  { label: 'Canadian Dollar', value: 'CAD' },
  { label: 'Cape Verdean Escudo', value: 'CVE' },
  { label: 'Cayman Islands Dollar', value: 'KYD' },
  { label: 'Central African Cfa Franc', value: 'XAF' },
  { label: 'Cfp Franc', value: 'XPF' },
  { label: 'Chilean Peso', value: 'CLP' },
  { label: 'Chinese Renminbi Yuan', value: 'CNY' },
  { label: 'Colombian Peso', value: 'COP' },
  { label: 'Comorian Franc', value: 'KMF' },
  { label: 'Congolese Franc', value: 'CDF' },
  { label: 'Costa Rican Colón', value: 'CRC' },
  { label: 'Croatian Kuna', value: 'HRK' },
  { label: 'Czech Koruna', value: 'CZK' },
  { label: 'Danish Krone', value: 'DKK' },
  { label: 'Djiboutian Franc', value: 'DJF' },
  { label: 'Dominican Peso', value: 'DOP' },
  { label: 'East Caribbean Dollar', value: 'XCD' },
  { label: 'Egyptian Pound', value: 'EGP' },
  { label: 'Ethiopian Birr', value: 'ETB' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Falkland Islands Pound', value: 'FKP' },
  { label: 'Fijian Dollar', value: 'FJD' },
  { label: 'Gambian Dalasi', value: 'GMD' },
  { label: 'Georgian Lari', value: 'GEL' },
  { label: 'Gibraltar Pound', value: 'GIP' },
  { label: 'Guatemalan Quetzal', value: 'GTQ' },
  { label: 'Guinean Franc', value: 'GNF' },
  { label: 'Guyanese Dollar', value: 'GYD' },
  { label: 'Haitian Gourde', value: 'HTG' },
  { label: 'Honduran Lempira', value: 'HNL' },
  { label: 'Hong Kong Dollar', value: 'HKD' },
  { label: 'Hungarian Forint', value: 'HUF' },
  { label: 'Icelandic Króna', value: 'ISK' },
  { label: 'Indian Rupee', value: 'INR' },
  { label: 'Indonesian Rupiah', value: 'IDR' },
  { label: 'Israeli New Sheqel', value: 'ILS' },
  { label: 'Jamaican Dollar', value: 'JMD' },
  { label: 'Japanese Yen', value: 'JPY' },
  { label: 'Kazakhstani Tenge', value: 'KZT' },
  { label: 'Kenyan Shilling', value: 'KES' },
  { label: 'Kyrgyzstani Som', value: 'KGS' },
  { label: 'Lao Kip', value: 'LAK' },
  { label: 'Lebanese Pound', value: 'LBP' },
  { label: 'Lesotho Loti', value: 'LSL' },
  { label: 'Liberian Dollar', value: 'LRD' },
  { label: 'Macanese Pataca', value: 'MOP' },
  { label: 'Macedonian Denar', value: 'MKD' },
  { label: 'Malagasy Ariary', value: 'MGA' },
  { label: 'Malawian Kwacha', value: 'MWK' },
  { label: 'Malaysian Ringgit', value: 'MYR' },
  { label: 'Maldivian Rufiyaa', value: 'MVR' },
  { label: 'Mauritanian Ouguiya', value: 'MRO' },
  { label: 'Mauritian Rupee', value: 'MUR' },
  { label: 'Mexican Peso', value: 'MXN' },
  { label: 'Moldovan Leu', value: 'MDL' },
  { label: 'Mongolian Tögrög', value: 'MNT' },
  { label: 'Moroccan Dirham', value: 'MAD' },
  { label: 'Mozambican Metical', value: 'MZN' },
  { label: 'Myanmar Kyat', value: 'MMK' },
  { label: 'Namibian Dollar', value: 'NAD' },
  { label: 'Nepalese Rupee', value: 'NPR' },
  { label: 'Netherlands Antillean Gulden', value: 'ANG' },
  { label: 'New Taiwan Dollar', value: 'TWD' },
  { label: 'New Zealand Dollar', value: 'NZD' },
  { label: 'Nicaraguan Córdoba', value: 'NIO' },
  { label: 'Nigerian Naira', value: 'NGN' },
  { label: 'Norwegian Krone', value: 'NOK' },
  { label: 'Pakistani Rupee', value: 'PKR' },
  { label: 'Panamanian Balboa', value: 'PAB' },
  { label: 'Papua New Guinean Kina', value: 'PGK' },
  { label: 'Paraguayan Guaraní', value: 'PYG' },
  { label: 'Peruvian Nuevo Sol', value: 'PEN' },
  { label: 'Philippine Peso', value: 'PHP' },
  { label: 'Polish Złoty', value: 'PLN' },
  { label: 'Qatari Riyal', value: 'QAR' },
  { label: 'Romanian Leu', value: 'RON' },
  { label: 'Russian Ruble', value: 'RUB' },
  { label: 'Rwandan Franc', value: 'RWF' },
  { label: 'São Tomé and Príncipe Dobra', value: 'STD' },
  { label: 'Saint Helenian Pound', value: 'SHP' },
  { label: 'Salvadoran Colón', value: 'SVC' },
  { label: 'Samoan Tala', value: 'WST' },
  { label: 'Saudi Riyal', value: 'SAR' },
  { label: 'Serbian Dinar', value: 'RSD' },
  { label: 'Seychellois Rupee', value: 'SCR' },
  { label: 'Sierra Leonean Leone', value: 'SLL' },
  { label: 'Singapore Dollar', value: 'SGD' },
  { label: 'Solomon Islands Dollar', value: 'SBD' },
  { label: 'Somali Shilling', value: 'SOS' },
  { label: 'South African Rand', value: 'ZAR' },
  { label: 'South Korean Won', value: 'KRW' },
  { label: 'Sri Lankan Rupee', value: 'LKR' },
  { label: 'Surinamese Dollar', value: 'SRD' },
  { label: 'Swazi Lilangeni', value: 'SZL' },
  { label: 'Swedish Krona', value: 'SEK' },
  { label: 'Swiss Franc', value: 'CHF' },
  { label: 'Tajikistani Somoni', value: 'TJS' },
  { label: 'Tanzanian Shilling', value: 'TZS' },
  { label: 'Thai Baht', value: 'THB' },
  { label: 'Tongan Paʻanga', value: 'TOP' },
  { label: 'Trinidad and Tobago Dollar', value: 'TTD' },
  { label: 'Turkish Lira', value: 'TRY' },
  { label: 'Ugandan Shilling', value: 'UGX' },
  { label: 'Ukrainian Hryvnia', value: 'UAH' },
  { label: 'United Arab Emirates Dirham', value: 'AED' },
  { label: 'United States Dollar', value: 'USD' },
  { label: 'Uruguayan Peso', value: 'UYU' },
  { label: 'Uzbekistani Som', value: 'UZS' },
  { label: 'Vanuatu Vatu', value: 'VUV' },
  { label: 'Vietnamese Đồng', value: 'VND' },
  { label: 'West African Cfa Franc', value: 'XOF' },
  { label: 'Yemeni Rial', value: 'YER' },
  { label: 'Zambian Kwacha', value: 'ZMW' },
];

export const variableType = {
  NUMBER: 0,
  TEXT: 1,
  CATEGORICAL: 2,
  MULTICATEGORICAL: 3,
  GRAPHIC: 4,
};

export const getVariableTypeLabel = (type) => {
  switch (type) {
    case variableType.TEXT:
      return { color: 'primary', label: 'text' };
    case variableType.NUMBER:
      return { color: 'secondary', label: 'numeric' };
    case variableType.CATEGORICAL:
      return { color: 'warning', label: 'categorical' };
    case variableType.MULTICATEGORICAL:
      return { color: 'success', label: 'multicatigorical' };
    case variableType.GRAPHIC:
      return { color: 'purple', label: 'graphic' };
    default:
      return { color: 'danger', label: 'unknown' };
  }
};

export const referenceModalOptions = [
  {
    label: 'Chicken',
    value: 'chicken',
  },
  {
    label: 'Parrot',
    value: 'parrot',
  },
  {
    label: 'Rabbit',
    value: 'rabbit',
  },
  {
    label: 'Mammal',
    value: 'mammal',
  },
  {
    label: 'Dog',
    value: 'dog',
  },
  {
    label: 'Cat',
    value: 'cat',
  },
  {
    label: 'Reptile',
    value: 'reptile',
  },
  {
    label: 'Snake',
    value: 'snake',
  },
  {
    label: 'Bearded Dragon',
    value: 'bearded_dragon',
  },
  {
    label: 'Turtle',
    value: 'turtle',
  },
];
