import { toast } from 'react-hot-toast';

const NotificationUtil = {
  info: (message) => {
    toast(message, {
      style: {
        border: '1px solid #1e90ff',
        padding: '8px 12px',
        color: '#1e90ff',
      },
      iconTheme: {
        primary: '#1e90ff',
        secondary: '#FFFAEE',
      },
      position: 'top-right',
      duration: 5000,
    });
  },
  warning: (message) => {
    toast(message, {
      style: {
        border: '1px solid #f57c00',
        padding: '8px 12px',
        color: '#f57c00',
      },
      iconTheme: {
        primary: '#f57c00',
        secondary: '#FFFAEE',
      },
      position: 'top-right',
      duration: 5000,
    });
  },
  success: (message) => {
    toast.success(message, {
      style: {
        border: '1px solid #15803d',
        padding: '8px 12px',
        color: '#15803d',
      },
      iconTheme: {
        primary: '#15803d',
        secondary: '#FFFAEE',
      },
      position: 'top-right',
      duration: 5000,
    });
  },
  error: (message) => {
    toast.error(message, {
      style: {
        border: '1px solid #b91c1c',
        padding: '8px 12px',
        color: '#b91c1c',
      },
      iconTheme: {
        primary: '#b91c1c',
        secondary: '#FFFAEE',
      },
      position: 'top-right',
      duration: 5000,
    });
  },
};

export default NotificationUtil;
