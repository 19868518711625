import React from 'react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    if (error.name === 'ChunkLoadError') {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
