const localStorageKeys = {
  moichorToken: 'moichor-token',
  moichorRefreshToken: 'moichor-refresh-token',
  moichorResetToken: 'moichor-reset-token',
  direction: 'direction',
  moichorUser: 'moichor-user',
  moichorQRCode: 'moichor-qr-code',
};

export default localStorageKeys;
